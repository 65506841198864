/* -'Become a member' block
-----------------------------------------------------------------------------*/
.become-a-member-block {
  @include section-margin;
  @include wrap-wide;

  &.become-a-member-block--thin {
    @include wrap-thin;
  }
}

.become-a-member-content {
  @include section-margin-bottom(0.5);

  max-width: $wrap-width-thin;
}

/* Only show the first part of form with member type until the user interact
with the form. */
.member-registration-form-start {
  .form-field-group--information_section .form-field,
  .form-footer {
    display: none;
  }
}
.member-registration-form-start,
.member-registration-form-payment {
  /* Hide the form errors in top since a new error div is placed below the
  submit button, easier for the user to notice. */
  .form-errors-wrap {
    display: none;
  }
  .member-registration-form-errors {
    margin-top: 20px;

    .form-errors-wrap {
      display: block;
    }
  }

  /* Add som extra margin to scroll when scrolling to next form fields, scroll
  is handle in JS */
  .form-field-group--information_section {
    scroll-margin: 100px;
  }
  .form-field-group--information_section .form-field,
  .form-footer {
    @include edge-wrap-thin;
  }
  &.member-registration-form-start--full {
    .form-field-group--information_section .form-field,
    .form-footer {
      display: block;
    }
  }
  .form-field--member_type {
    .radio-fields {
      @include grid-wrap;
      @include grid-pull-bottom;

      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }

  // Reset form styling
  .form-field-group,
  .form-field-group-fields {
    @include grid-wrap-reset;
  }
  .form-field-group-fields,
  .form-field,
  .form-field-group {
    @include grid-item-reset;
  }
}

.radio-field-member-type {
  @include grid-item-base;
  @include grid-margin-bottom;

  flex: 0 0 100%;
  max-width: 100%;
}

.radio-field-member-type-inner {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  border: 3px solid transparent;
  border-radius: 15px;
  background-color: $color-green-pale;

  label {
    @include h;

    margin-bottom: 5px;
    font-weight: $font-weight-bold !important;
    font-size: rem(20px);
  }
  .membership-price {
    display: block;
    color: $color-green;
    font-weight: $font-weight-bold;
    font-size: rem(30px);
  }

  input:checked ~ & {
    border-color: green;
  }
  input:focus ~ & {
    outline: 3px dotted #000;
  }
}
.member-type-content {
  margin-top: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .description {
    flex-grow: 1;
    margin-bottom: 30px;

    p:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    @include checklist;
  }
  .price-container {
    margin-bottom: 5px;
  }
}

.become-a-member-block {
  label,
  .field-group-label {
    font-weight: $font-weight-semibold;
  }
  .radio-field,
  .form-field--checkbox {
    label {
      font-weight: $font-weight-regular;
    }
  }
}
.cancel-member-registration-form-btn {
  display: block;
}
.member-registration-payment-iframe {
  // The iframe content is 400px wide and centered which looks off, so limit
  // the container. The iframe content is responsive and should continue to
  // work even if it's changed from 400 in the future.
  max-width: 400px;
}
.edit-member-registration-form-btn,
.cancel-member-registration-form-btn {
  @include btn-plain;

  margin-top: 10px;
}
.payment-reference-info {
  margin-top: 10px;
}

.radio-field-member-type.radio-field-member-type-count--1 {
  display: inline-flex;
  flex: 0 0 100%;
  max-width: 100%;
}
// Media queries
@include media-min(rem(500px)) {
  .radio-field-member-type-inner {
    padding: 25px;
    border-radius: 18px;
  }
}

@include media-min(rem(700px)) {
  .radio-field-member-type {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@include media-min(rem(1000px)) {
  .radio-field-member-type {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
