/* -Generic card used for news, events and highlight pages
---------------------------------------------------------------------------- */
.page-card {
  position: relative;
  max-width: 400px;
}

.page-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 20px;
  background: #fff;

  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    box-shadow: inset 0 0 0 2px $color-body-foreground;
  }
}

.page-card-title {
  margin: 0 0 10px;
  font-size: rem($h3);
}
.page-card-title--small {
  font-size: rem(14px);
}

.page-card-excerpt,
.page-card-image {
  margin-top: auto;
  margin-bottom: 0;
}
.page-card-image {
  position: relative;
  // https://github.com/philipwalton/flexbugs#flexbug-5
  // https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
  min-height: 1px;
  transition: opacity 0.15s ease;

  img {
    width: 100%;
  }
}

@include media-max(rem(450px)) {
  .page-card-inner {
    padding: 15px;
  }
  .page-card-title {
    font-size: rem($h3 - 2px);
  }
}

/* --------------- List of page cards --------------- */
/* .page-cards-wrap {
  @include wrap-custom(rem(400px));
}

.page-cards {
  @include grid-wrap;
  @include grid-pull-bottom;

  display: flex;
  flex-wrap: wrap;

  .page-card {
    @include grid-item(1);
    @include grid-margin-bottom;
  }
  .page-card--has-bg {
    display: flex;
    flex-direction: column;

    .page-card-inner {
      flex-grow: 1;
      min-height: 200px;
    }
  }
}

@include media-min(rem(650px)) {
  .page-cards-wrap--cols-1 {
    @include wrap-width-custom(rem(450px));
  }
  .page-cards-wrap--cols-2,
  .page-cards-wrap--cols-3,
  .page-cards-wrap--cols-4 {
    @include wrap-width-thin;

    .page-card {
      @include grid-item-columns(1 / 2);
    }
  }
}

@include media-min(rem(1100px)) {
  .page-cards-wrap--cols-3,
  .page-cards-wrap--cols-4 {
    @include wrap-width-wide;

    .page-card {
      @include grid-item-columns(1 / 3);
    }
  }
} */
