/* -Loan calculation form
---------------------------------------------------------------------------- */
@use "sass:math";

$loan-calc-breakpoint-medium: 550px;
$loan-calc-breakpoint-large: 800px;
$color-loan-calc-result-border-green: desaturate(
  darken($color-section-green-background, 25%),
  5%
);
$color-loan-calc-result-border-yellow: desaturate(
  darken($color-section-yellow-background, 30%),
  15%
);
$color-loan-calc-result-border-gray: desaturate(
  darken($color-gray-light, 30%),
  15%
);
.loan-calc-wrap {
  @include section-margin;

  .section-block--green-dark & {
    @include section-padding;

    background-color: #fff;
    border-radius: 2px;
    color: $color-body-foreground;
  }
}

.loan-calc-title {
  margin-bottom: 1rem;
}

.loan-calc {
  @include grid-wrap;

  fieldset {
    @include grid-item(1);
    margin-bottom: $text-spacing;
  }
}
.loan-calc,
.living-situation {
  legend {
    font-size: rem(14px);
  }
  input[type='range'] {
    margin-top: 10px;
  }
}
@include media-min(rem(450px)) {
  .loan-calc--2-fields {
    fieldset {
      @include grid-item-columns(1 * 0.5);
    }
  }
}
@include media-between(rem(450px), rem(649px)) {
  .loan-calc--3-fields {
    fieldset + fieldset {
      @include grid-item-columns(1 * 0.5);
    }
  }
}
@include media-min(rem(650px)) {
  .loan-calc--1-fields {
    fieldset {
      @include grid-item-columns(math.div(2, 3));
    }
  }
  .loan-calc--3-fields {
    fieldset {
      @include grid-item-columns(math.div(1, 3));
    }
  }
}
@include media-min(rem($loan-calc-breakpoint-large)) {
  .loan-calc--2-fields {
    fieldset {
      @include grid-item-columns(1 * 0.5);
    }
  }
}

// Loan calc deposit
.loan-calc-deposit {
  margin-bottom: em(24px - 10px) !important;

  .loan-calc--2-fields & {
    @include grid-item-columns(1);
  }

  .loan-calc-deposit-inner {
    padding: 0.5em 1.25em;
    background-color: $color-gray-light;
    border: 1px solid $color-loan-calc-result-border-gray;

    // Visually align with the top of the extra block text
    margin-top: 0.5em;
  }

  legend {
    font-size: rem(16px);
  }
  .deposit-choices {
    margin-bottom: 5px;
  }
  input {
    margin-right: 5px;
  }
  label {
    display: block;
    font-size: rem(13px);
  }
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @include media-min(rem($loan-calc-breakpoint-large)) {
    .loan-calc--2-fields & {
      @include grid-item-columns(math.div(2, 3));

      .loan-calc-deposit-content {
        display: flex;
      }
    }

    .deposit-choices {
      flex-shrink: 0;
      margin-left: 1em;
      margin-top: 0;
    }
  }
}

.loan-calc-result {
  margin-top: 10px;
  transition: opacity 0.1s ease;

  .no-js & {
    display: none;
  }
  p {
    margin-top: 5px;
    margin-bottom: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
  .value {
    font-weight: $font-weight-semibold;
    white-space: nowrap;
  }
}
.loan-calc-result--has-error {
  opacity: 0.4;
}
.loan-calc-result--has-result {
  opacity: 1;
}

.loan-calc-fine-print {
  font-size: rem(10px);
}

.loan-calc-result-main {
  p > span {
    vertical-align: middle;
  }
  .label {
    font-size: rem(13px);
  }
  .value {
    font-size: rem(18px);
  }
}
.loan-calc-result-main-inner,
.saving-system-main-inner,
.living-situation,
.possible-loan {
  padding: 0.5em 1.25em;
  background: $color-section-green-background;
  border: 1px solid $color-loan-calc-result-border-green;
  // Visually align with the top of the extra block text
  margin-top: 0.5em;

  .section-block--yellow &,
  .section-block--green & {
    background: #fff;
  }
  .section-block--yellow & {
    border-color: $color-loan-calc-result-border-yellow;
  }
}
.living-situation,
.possible-loan {
  padding-top: 1em;
  padding-bottom: 1em;
}
.loan-calc-result-total {
  flex-grow: 1;

  .value {
    display: block;
    min-width: 5.5em;
    font-size: rem(28px);
    color: $color-green;
    white-space: nowrap;
  }
}
.loan-calc-result-total-parts,
.loan-calc-result-saving-info {
  min-width: 12em;
}
// Applying flex-basis to -parts results in broken layout in IE11.
// https://github.com/philipwalton/flexbugs#flexbug-7
.loan-calc-result-saving-info {
  flex-basis: 12em;
}
.loan-calc-result-total-parts {
  .value {
    white-space: nowrap;
  }
}

.loan-calc-result-extra,
.possible-loan-extra {
  margin-top: $text-spacing;
  font-size: rem(13px);
}

.saving-system-spacing {
  margin: 0.5em 0;
  text-align: center;
}

.saving-system-selection {
  font-size: rem(13px);
}

.amortisation-description {
  font-size: rem(11px);
  margin: 0 0 3px;
}

.saving-system-selected-description {
  min-height: 3em;

  p {
    margin: 5px 0 0 !important;
  }
}

.living-situation,
.possible-loan {
  margin-bottom: 2em;
  font-size: rem(14px);
}

.living-situation-choice-part,
.living-situation-monthly-cost {
  max-width: rem(350px);
}
.living-situation-choice-part {
  margin-bottom: 1.5em;

  select {
    width: 100%;
    font-size: 1rem;
  }
}
.living-situation-monthly-cost {
  input {
    width: 100%;
  }
}

.living-situation-choice-part--radios {
  legend {
    margin-bottom: 0.25em;
  }
  > div {
    display: flex;
  }
  label {
    margin-right: 1em;
  }
  input,
  span {
    vertical-align: middle;
  }
}

.possible-loan--title {
  margin-bottom: 0.5em;
}

.possible-loan-table {
  width: 100%;
  margin: 0;
  border: 1px solid $color-green;

  td {
    border-top: 1px solid $color-green;
    border-bottom: 1px solid $color-green;
    color: $color-green;
  }
  tr:nth-child(odd) td {
    background-color: $color-green;
    color: #fff;
  }
}

@include media-max(rem($loan-calc-breakpoint-large - 1px)) {
  .loan-calc-result-total-parts {
    .icon {
      display: none;
    }
  }
}

@include media-min(rem($loan-calc-breakpoint-medium)) {
  .loan-calc-result {
    display: flex;
    align-items: flex-start;
  }
  .loan-calc-result-main {
    margin-right: 50px;
  }
  .loan-calc-result-extra,
  .possible-loan-extra {
    margin-top: 0;
    min-width: rem(160px);
  }
}

@include media-min(rem($loan-calc-breakpoint-large)) {
  .loan-calc-result {
    @include grid-wrap;

    display: block;
  }

  .loan-calc-result-main-wrapper {
    @include grid-item(math.div(2, 3));
    margin: 0;
  }

  .loan-calc-result-main {
    @include grid-item(1);
    padding: 0;
    margin: 0;
  }
  .loan-calc-result-main-inner,
  .saving-system-select-inner {
    display: flex;
    align-items: center;
  }
  .saving-system-selection {
    margin-left: 1em;
  }
  .saving-system-description {
    max-width: 12em;
  }
  .loan-calc-result-extra,
  .possible-loan-extra {
    @include grid-item(math.div(1, 3));
    margin-top: 0;
  }
  .loan-calc-result-total-parts,
  .loan-calc-result-saving-info {
    min-width: 14em;
    margin-left: 0.25em;
    padding: 0.5em 0 0.5em 2em;
  }
  .loan-calc-result-saving-info {
    flex-basis: 14em;
  }
  .loan-calc-result-total-parts {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $color-green;
      color: $color-loan-calc-result-border-green;

      .section-block--yellow & {
        color: $color-loan-calc-result-border-yellow;
      }
    }
    .icon--curly-bracket {
      width: em($icon-curly-bracket-width);
      height: em($icon-curly-bracket-height);
    }
    .icon--curly-bracket-small {
      width: em($icon-curly-bracket-small-width);
      height: em($icon-curly-bracket-small-height);
    }
  }
}
