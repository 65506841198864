/* -Range input with min and max labels
---------------------------------------------------------------------------- */
// Using pseudo elements with content: attr(min or max) would be neat, but
// pseudo elements aren't supported on inputs.

$range-input-label-size: 10px;

.range-input {
  position: relative;
  // Account for line-height
  padding-bottom: em($range-input-label-size * 1.5);
}

.range-label {
  position: absolute;
  bottom: 0;
  font-size: rem($range-input-label-size);
  color: $color-lightest-accessible-gray-on-white;
}
.range-label--min {
  left: 0;
}
.range-label--max {
  right: 0;
}
