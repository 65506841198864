/* -Section block
---------------------------------------------------------------------------- */
.section-block {
  @include section-padding;

  > .blocks:first-child {
    > *:first-child {
      margin-top: 0;
    }
  }

  > .block-content:first-child {
    @include section-margin-bottom;

    h2 {
      @extend %h1;

      color: $color-green;
    }
  }
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.section-block--yellow {
  background: $color-section-yellow-background;
}
.section-block--green {
  background: $color-section-green-background;
}
.section-block--green-dark {
  background: $color-section-green-dark-background;
  color: #fff;

  > .block-content:first-child h2 {
    color: #fff;
  }
  a {
    color: inherit;
  }
}

.section-block--center {
  > .block-content {
    text-align: center;
  }
}
