/* -Grus & Guld parts
---------------------------------------------------------------------------- */

/* -------- Header with menu -------- */
$gg-breakpoint-menu-toggle: rem(1000px);
.grus-guld-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
$gg-header-top-item-radius: 20px;
.grus-guld-header-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .icon {
    margin-right: 5px;

    &.icon--arrow {
      transform: rotate(180deg);
    }
  }
  a {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    background-color: $color-green-pale;
    color: $color-green;
    font-size: rem(12px);
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .link-item {
    border-bottom-right-radius: $gg-header-top-item-radius;
  }
  .search-item {
    border-bottom-left-radius: $gg-header-top-item-radius;
  }
}
.grus-guld-header-search {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 25px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  text-align: center;

  .search-form {
    width: 100%;
  }
  .search-open & {
    display: block;
  }
}
$logo-spacing-gg: 20px;
.grus-guld-logo {
  margin: 10px $logo-spacing-gg $logo-spacing-gg $logo-spacing-gg;
  max-width: 250px;
}
.grus-guld-menu {
  position: relative;
  width: 100%;
  text-align: right;
  background-color: $color-main;
  color: #fff;

  li {
    display: block;
    text-transform: uppercase;
  }
  .current-item {
    a {
      opacity: 0.6;
    }
  }
  a {
    padding: 10px 15px;
    display: block;
    color: inherit;
    text-decoration: none;
    font-size: rem(18px);
    font-weight: $font-weight-medium;

    &:hover,
    &:focus {
      background-color: $color-purple;
      outline-color: inherit;
    }
  }
}
.grus-guld-nav {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding-bottom: 40px;
  width: 100%;
  text-align: center;
  background-color: inherit;
  z-index: $z-grus-guld-mobile-menu;

  .nav-open--gg & {
    display: block;
  }
}
.grus-guld-menu-toggle {
  position: relative;
  padding-right: 35px;
  box-shadow: none;
  background: transparent !important;
  transition: color 0.15s ease;

  &:hover,
  &:focus {
    border-color: currentColor;
  }
}

.menu-toggle-icon {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);

  &,
  &::before,
  &::after {
    position: absolute;
    width: 13px;
    height: 2px;
    background-color: currentColor;
    transition: background-color 0.15s ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    transition: transform 0.15s ease;
  }
  &::before {
    top: 0;
    transform: translateY(-5px);
  }
  &::after {
    bottom: 0;
    transform: translateY(5px);
  }
  .nav-open--gg & {
    background-color: rgba(255, 255, 255, 0);

    &::before {
      transform: translateY(0) rotate(-225deg);
    }
    &::after {
      transform: translateY(0) rotate(225deg);
    }
  }
}

@include media-min(rem(410px)) {
  .grus-guld-header-top {
    a {
      padding: 5px 30px;
    }
  }
}
@include media-min(rem(600px)) {
  .grus-guld-logo {
    margin-top: 0;
  }
}
@include media-min(rem(800px)) {
  .grus-guld-header-search {
    width: auto;
  }
  .grus-guld-logo {
    max-width: 350px;
  }
}

// Menu for larger screens
@include media-min($gg-breakpoint-menu-toggle) {
  .grus-guld-menu {
    background-color: $color-main;
    color: #fff;

    ul {
      display: flex;
    }

    li {
      flex-grow: 1;
    }
    a {
      padding: 10px 25px;
      display: block;
      color: inherit;
      text-decoration: none;
      font-size: rem(16px);
      font-weight: $font-weight-medium;
      white-space: nowrap;

      &:hover,
      &:focus {
        background-color: $color-purple;
      }
    }
  }

  .grus-guld-nav {
    @include edge-wrap-mega;

    display: block;
    position: static;
    padding-bottom: 0;
  }

  .grus-guld-menu-toggle {
    display: none;
  }
}

// Menu for larger screens
@include media-min(rem(1300px)) {
  .grus-guld-logo {
    max-width: 500px;
  }
  .grus-guld-menu {
    a {
      font-size: rem(18px);
    }
  }
}
/* -------- End header with menu -------- */

/* -------- Footer -------- */
.grus-guld-footer {
  .grus-guld-logo {
    max-width: 230px;
    margin: 0;
    padding: 0;
  }
}
.grus-guld-footer-inner {
  padding: 40px 0;
  border-bottom: 1px solid rgba(#fff, 0.2);
}
.grus-guld-footer-content {
  margin-top: 30px;
}

@include media-min(rem(850px)) {
  .grus-guld-footer-inner {
    display: flex;
  }
  .grus-guld-footer-content {
    flex-grow: 1;
    margin: 0 0 0 60px;
    text-align: right;
  }
}
@include media-min(rem(1200px)) {
  .grus-guld-footer {
    .grus-guld-logo {
      max-width: 330px;
    }
  }
}
/* -------- End footer -------- */

/* -------- Grus & Guld page -------- */

.gg-page {
  @include wrap-custom($gg-wrap-width-wide);
}
.gg-page-content {
  @include section-margin-bottom;
}

@include media-min($gg-breakpoint-sidebar-in-col) {
  .gg-page {
    display: flex;
  }

  .gg-page-sidebar {
    flex-basis: $gg-list-large-right-col-width;
    flex-grow: 1;
    flex-shrink: 0;
    align-self: flex-start;
  }

  .gg-page-content {
    margin-right: rem(40px);
  }
}
/* -------- End Grus & Guld page -------- */
