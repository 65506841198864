/* -In-page section navigation
---------------------------------------------------------------------------- */
.no-intro .content-sections--has-nav {
  border-top: 1px solid $color-separator;
}
.section-nav {
  position: sticky;
  top: 0;
  padding: 0 10px;
  border-bottom: 1px solid $color-separator;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(255, 255, 255, 0.98);
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: $z-section-nav;

  li {
    margin: 0 5px;
  }
  a {
    padding: 8px 20px;
    font-weight: $font-weight-medium;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @include media-max(rem(700px)) {
    li {
      margin: 0;
    }
    a {
      padding: 8px 15px;
      font-size: rem(14px);
    }
  }
}
