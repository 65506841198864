/* -Grid
-----------------------------------------------------------------------------*/
// Each group of grid items must be wrapped with a grid-wrap, which has a
// negative margin equal to the grid gutter. This makes them infinitely
// nestable; just use a new wrap with child items.
//
// Idea based on csswizardry-grids.
//
// Create a grid with three columns.
//
//   .wrapper-element {
//     @include grid-wrap;
//   }
//   .child-elements {
//     @include grid-item(1 / 3);
//   }
//
// Create a grid with four columns and a smaller gutter. The second gutter
// value is for when the 'tight grid' breakpoint is in effect. Values should
// probably always be the same for wrapper and items, do separate overriding
// rules if needed to avoid confusion.
// If not setting gutter values, the global default will be used.
//
//   .wrapper-element {
//     @include grid-wrap(20px, 10px);
//   }
//   .child-elements {
//     @include grid-item(1 / 4, 20px, 10px);
//   }
//
// Changing columns should be done with the specific mixin rather than the
// grid-item() mixin, to avoid unnecessary duplication.
//
//   .child-elements {
//     @include grid-item(1 / 4);
//
//     @include media-max(800px) {
//       @include grid-item-columns(1 / 3);
//     }
//   }

$grid-gutter: 50px;
$grid-gutter-tight: $grid-gutter * 0.5;

/*-------------------- Wrap --------------------*/
@mixin grid-wrap-gutter-size($size) {
  margin-left: -$size;
}
@mixin grid-wrap($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-wrap-gutter-size($size);

  @include media-max($breakpoint-grid-tight) {
    @include grid-wrap-gutter-size($size-tight);
  }
}

/* -------------------- Margin and padding -------------------- */
// Margin or padding matching the current grid gutter size.

@mixin grid-gutter-rule(
  $rule,
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  $rules: ();
  @if type-of($rule) == 'list' {
    $rules: join($rules, $rule);
  } @else {
    $rules: append($rules, $rule);
  }

  @each $rule in $rules {
    #{$rule}: $size;
  }
  @include when-grid-tight {
    @each $rule in $rules {
      #{$rule}: $size-tight;
    }
  }
}

@mixin grid-margin-top($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-top, $size, $size-tight);
}

@mixin grid-margin-right($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-right, $size, $size-tight);
}

@mixin grid-margin-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(margin-bottom, $size, $size-tight);
}

@mixin grid-margin-left($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-left, $size, $size-tight);
}

@mixin grid-pull-bottom($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(margin-bottom, -$size, -$size-tight);
}

@mixin grid-padding-top($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(padding-top, $size, $size-tight);
}

@mixin grid-padding-right(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(padding-right, $size, $size-tight);
}

@mixin grid-padding-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-gutter-rule(padding-bottom, $size, $size-tight);
}

@mixin grid-padding-left($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-gutter-rule(padding-left, $size, $size-tight);
}

/*-------------------- Item --------------------*/
@mixin grid-item-columns($fraction) {
  @if type-of($fraction) != 'number' or $fraction > 1 {
    @error 'Grid item columns must be a fraction no greater than 1 (e.g. 1 / 4), got #{$fraction}';
  }
  width: $fraction * 100%;
}
@mixin grid-item-gutter-size($size) {
  padding-left: $size;
}
@mixin grid-item-gutter($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-item-gutter-size($size);

  @include media-max($breakpoint-grid-tight) {
    @include grid-item-gutter-size($size-tight);
  }
}
@mixin grid-item-base($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  @include grid-item-gutter($size, $size-tight);

  display: inline-block;
  vertical-align: top;
}
@mixin grid-item(
  $fraction,
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  @include grid-item-base($size, $size-tight);
  @include grid-item-columns($fraction);
}

/*-------------------- Spacing --------------------*/
// Vertical margin matching the grid gutter size
@mixin grid-margin-top($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-top: $size;

  @include when-grid-tight {
    margin-top: $size-tight;
  }
}
@mixin grid-margin-bottom(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  margin-bottom: $size;

  @include when-grid-tight {
    margin-bottom: $size-tight;
  }
}
@mixin grid-pull-bottom($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-bottom: -$size;

  @include when-grid-tight {
    margin-bottom: -$size-tight;
  }
}

@mixin grid-padding-left($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  padding-left: $size;

  @include when-grid-tight {
    padding-left: $size-tight;
  }
}
@mixin grid-padding-right(
  $size: $grid-gutter,
  $size-tight: $grid-gutter-tight
) {
  padding-right: $size;

  @include when-grid-tight {
    padding-right: $size-tight;
  }
}

@mixin grid-margin-left($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-left: $size;

  @include when-grid-tight {
    margin-left: $size-tight;
  }
}
@mixin grid-margin-right($size: $grid-gutter, $size-tight: $grid-gutter-tight) {
  margin-right: $size;

  @include when-grid-tight {
    margin-right: $size-tight;
  }
}

/*-------------------- Reset --------------------*/
@mixin grid-wrap-reset {
  margin-left: 0;
}
@mixin grid-item-reset {
  display: block;
  width: auto;
  padding-left: 0;
}

/*-------------------- Helpers --------------------*/
// Media query mixins for when the grid gutters aren't tight.
//
// .selector {
//   margin-top: 20px;
//
//   @include when-grid-tight {
//     margin-top: 10px;
//   }
// }
@mixin when-grid-tight {
  @include media-max($breakpoint-grid-tight) {
    @content;
  }
}
@mixin when-grid-wide {
  @include media-min($breakpoint-grid-tight + rem(1px)) {
    @content;
  }
}
