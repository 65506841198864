/* -Content on background image block
---------------------------------------------------------------------------- */
.content-on-bg-block-wrap {
  @include section-margin;
}
.content-on-bg-block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  background-size: cover;
}
.content-on-bg-block-content {
  @include edge-wrap;

  padding: 20px 10px;
  text-align: center;
  color: $color-green;
}

@include media-min(rem(800px)) {
  .content-on-bg-block-content {
    padding: 40px 20px;
  }
}
