/* -General table styles
---------------------------------------------------------------------------- */
.table-wrap {
  overflow-x: auto;
  margin-bottom: $text-spacing;

  table {
    margin-bottom: 0;
  }
}

table {
  border-collapse: collapse;
  border-top: 1px solid $color-separator;
  border-bottom: 1px solid $color-separator;
}

th,
td {
  padding: 5px 10px;
  border-top: 1px solid $color-separator;
  border-bottom: 1px solid $color-separator;
  font-size: rem(14px);

  thead & {
    background: #f2f2f2;

    .section-block--yellow &,
    .section-block--green &,
    .section-block--green-dark & {
      background: #fff;
    }
  }
}

th {
  text-align: left;
}
