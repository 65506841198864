/* -'Image and content' block
-----------------------------------------------------------------------------*/
$image-content-columns-breakpoint: 750px;

.image-content-block {
  @include section-margin;
}

.image-content-block-image {
  margin-bottom: 1em;
}

// When it's still a single column and getting a bit large, make the images
// slightly shorter if object-fit is supported.
@include media-between(
  rem(500px),
  rem($image-content-columns-breakpoint - 1px)
) {
  @supports (object-fit: cover) {
    .image-content-block-image {
      @include aspect-ratio('16:9');

      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// Go to two columns
@include media-min(rem($image-content-columns-breakpoint)) {
  .image-content-block-inner {
    display: flex;
  }
  .image-content-block:nth-child(2n + 1) {
    .image-content-block-image {
      margin-right: $grid-gutter;
    }
  }
  .image-content-block:nth-child(2n) {
    .image-content-block-inner {
      flex-direction: row-reverse;
    }
    .image-content-block-image {
      margin-left: $grid-gutter;
    }
  }
  .image-content-block-image {
    margin-bottom: 0;
    width: 40%;
  }
  .image-content-block-content {
    width: 60%;
  }
}

@include media-min(rem(900px)) {
  .image-content-block-image,
  .image-content-block-content {
    width: 50%;
  }
}
