// -------------------- Color definitions -------------------- //

$color-gray-dark: #414141;
$color-gray-light: #f3f3f3;
$color-blue: #3575d4;
$color-green: #0e6946;
$color-green-bright: #10aa6e;
$color-green-light: #a4ebcf;
$color-green-pale: #f2f8f5;
$color-beige: #fffaeb;
$color-purple: #4e1977;

// Lightest text color on white that meets WCAG AA accessibility requirement
$color-lightest-accessible-gray-on-white: #767676;

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

// Main body colors
$color-body-foreground: $color-gray-dark;
$color-body-background: #fff;

// Disabled text
$color-disabled: $color-lightest-accessible-gray-on-white;

// Brand things like selection color
$color-main: $color-green;

// Links and main buttons
$color-action: $color-blue;
$color-action-foreground: #fff;

// General separating borders
$color-separator: #d8d8d8;

// Section block backgrounds
$color-section-yellow-background: $color-beige;
$color-section-green-background: $color-green-pale;
$color-section-green-dark-background: $color-green;

// Forms
$color-form-field-border: #ccc;

// Footer
$color-footer-foreground: #fff;
$color-footer-background: $color-green;

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
$color-info-foreground: #06608c;
$color-info-background: #d5e9f9;
$color-warning-foreground: #715100;
$color-warning-background: #fffbd5;
