/* -Grus & Guld list page
---------------------------------------------------------------------------- */
@use "sass:math";

$gg-grid-gutter: rem(40px);
$gg-grid-gutter-tight: $gg-grid-gutter*0.5;

$gg-list-breakpoint-two-card-cols: rem(650px);
$gg-list-breakpoint-three-card-cols: rem(850px);
$gg-list-breakpoint-four-card-cols: rem(1200px);

$gg-breakpoint-sidebar-in-col: rem(1200px);
$gg-list-large-right-col-width: 31.5%;

.grus-guld-list {
  @include wrap-custom($gg-wrap-width-wide);
}
.article-list-group {
  @include grid-margin-bottom($gg-grid-gutter, $gg-grid-gutter-tight);
}

@include media-min($gg-breakpoint-sidebar-in-col) {
  // Hide this sidebar for larger screens and display the sidebar in the bottom
  //of page, after all articles since they are more important.
  .grus-guld-list {
    > .news-articles-sidebar {
      display: none;
    }

    // Empty list
    &.grus-guld-list--empty {
      display: flex;

      .grus-guld-list-empty {
        flex-grow: 1;
        margin-right: rem(40px);
      }

      .news-articles-sidebar {
        display: block;
        flex-basis: $gg-list-large-right-col-width;
        flex-shrink: 0;
      }
    }
  }
}

/* -------- General card styling -------- */
.grus-guld-card {
  position: relative;
  max-width: rem(840px) + $gg-grid-gutter;

  .overlay-link {
    @include grid-gutter-rule(left, $gg-grid-gutter, $gg-grid-gutter-tight);
  }
  .card-image {
    margin-bottom: 15px;
  }
  .card-title {
    @include h3;

    margin-top: 0;

    i {
      font-style: normal;
    }
  }
  .card-excerpt {
    margin-bottom: 0;
  }
}
.grus-guld-card-tags {
  margin-bottom: 8px;
  font-weight: $font-weight-bold;
  font-size: rem(14px);
  text-transform: uppercase;
  color: $color-green;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;

    .article-card-main & {
      justify-content: center;
    }

    li:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.grus-guld-card-author {
  margin-top: 10px;
  font-size: rem(12px);
  text-transform: uppercase;
  color: $color-green;

  p {
    margin-bottom: 0;
  }
}

.grus-guld-card-inner {
  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    .card-image {
      opacity: 0.9;
    }
    .card-title {
      text-decoration: underline;
    }
  }
}

@include media-min(rem(1200px)) {
  .grus-guld-card {
    .card-excerpt {
      font-size: rem(18px);
    }
  }
}
/* -------- End general card styling -------- */

/* -------- Intro with sidebar -------- */
.intro-with-sidebar {
  .articles .article-cols:first-child {
    text-align: center;
  }

  .article-card-main {
    text-align: center;

    .card-image {
      margin-bottom: 20px;
    }
    .card-title {
      @include h2;
    }
  }
  // Hide the sidebar for small screens and display the sidebar in the bottom
  //of page, after all articles since they are more important.
  .news-articles-sidebar {
    display: none;
  }
}

@include media-min($gg-breakpoint-sidebar-in-col) {
  .intro-with-sidebar {
    display: flex;

    .articles {
      @include grid-margin-right($gg-grid-gutter, $gg-grid-gutter-tight);
    }
    // Keep column width in sync with article group `Mixed blocks`
    .news-articles-sidebar {
      display: block;
      flex-basis: $gg-list-large-right-col-width;
      flex-grow: 1;
      flex-shrink: 0;
      align-self: flex-start;
    }
  }
}
/* -------- End intro with sidebar -------- */

/* -------- Cards in columns -------- */
.article-cols {
  @include grid-wrap($gg-grid-gutter, $gg-grid-gutter-tight);

  .grus-guld-card {
    @include grid-item(1, $gg-grid-gutter, $gg-grid-gutter-tight);
    @include grid-margin-bottom($gg-grid-gutter, $gg-grid-gutter-tight);
  }
}

@include media-min($gg-list-breakpoint-two-card-cols) {
  .article-cols--2,
  .article-cols--4 {
    .grus-guld-card {
      @include grid-item-columns(1*0.5);
    }
  }
}

@include media-min($gg-list-breakpoint-three-card-cols) {
  .article-cols--3 {
    .grus-guld-card {
      @include grid-item-columns(math.div(1, 3));
    }
  }
}

@include media-min($gg-list-breakpoint-four-card-cols) {
  .article-cols--4 {
    .grus-guld-card {
      @include grid-item-columns(1*0.25);
    }
  }
}
/* -------- End cards in columns -------- */

/* -------- Mixed blocks -------- */
@include media-min($gg-breakpoint-sidebar-in-col) {
  .article-group-mixed {
    display: flex;

    .group-mixed-left,
    .group-mixed-right {
      .grus-guld-card {
        @include grid-item-columns(1);
      }
    }

    .group-mixed-left {
      @include grid-margin-right($gg-grid-gutter, $gg-grid-gutter-tight);
    }
    // Keep column width in sync with article group `Intro with sidebar`
    .group-mixed-right {
      flex-basis: $gg-list-large-right-col-width;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}
/* -------- End mixed blocks -------- */
