/* -Text variations
-----------------------------------------------------------------------------*/

// Any continuous text.
// Only targets direct children since some tags may be wrapped in a rich-text
// div which itself has last-child targets that don't should have their margins
// zeroed.
@mixin running-text {
  > p,
  > ol,
  > ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.running-text {
  @include running-text;
}

// Tighter running text.
@mixin tight-text {
  p,
  ol,
  ul {
    margin-bottom: 0;
    margin-top: 0.75em;

    &:first-child {
      margin-top: 0;
    }
  }
}
.tight-text {
  @include tight-text;
}

// Larger and smaller text.
@mixin lead-text {
  margin-bottom: em($base-text-spacing, $lead-text-size);
  font-size: rem($lead-text-size);

  @include media-max($breakpoint-type-small) {
    font-size: rem($base-font-size + 2px);
  }
}
@mixin lead-text-reset {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: normal;
}
@mixin sub-text {
  margin-bottom: em($base-text-spacing, $sub-text-size);
  font-size: rem($sub-text-size);
}
.lead-text {
  @include lead-text;
}
.sub-text {
  @include sub-text;
}
.lead-text,
.sub-text {
  p,
  ol,
  ul {
    margin-bottom: inherit;
  }
}

// Inline text alongside things like icons.
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own.
  &:only-child {
    vertical-align: baseline;
  }
}

// List with check icon bullets.
@mixin checklist($circle-color: $color-green-bright, $check-color: #fff) {
  list-style: none;
  margin-left: 25px;

  li {
    position: relative;
    margin-top: 0.5em;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.25em;
      left: -25px;
      width: 15px;
      height: 15px;
      background: svg-url(
        '<svg width="15" height="15" viewBox="0 0 15 15"><circle fill="#{$circle-color}" cx="7.5" cy="7.5" r="7.5"/><path d="M10.2 4.4A1 1 0 0 1 12 5.5l-.1.1L7 11.4 3.4 8.3a1 1 0 0 1 1.1-1.6h.1L7 8.6l3.3-4.2Z" fill="#{$check-color}"/></svg>'
      );
    }
  }
}
