/* -News list and detail
---------------------------------------------------------------------------- */
.category {
  + h1,
  + h2 {
    margin-top: 0;
  }
}

/* ------------------- List ------------------- */
.news-list-page .block-content {
  @include wrap-width;
}

/* ------------------- Detail ------------------- */
.news-article-image {
  margin: 20px 0;
}
