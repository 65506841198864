/* -Sidebar with list of latest news articles
---------------------------------------------------------------------------- */
.news-articles-sidebar {
  padding: 20px 15px;
  background-color: $color-green-pale;
}
.sidebar-header {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0;
    color: $color-green;
  }
  img {
    max-width: 70px;
  }
}
.sidebar-list {
  margin: 0;
  list-style: none;

  li {
    padding-top: 15px;
    border-top: 1px solid rgba($color-green, 0.2);
  }
}

.sidebar-list-item {
  .category {
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    font-size: rem(14px);
    color: $color-green;
  }
  .publish-date {
    color: $color-green;
    font-size: rem(12px);
  }
}
.list-item-heading {
  margin-top: 0;
}
.list-item-excerpt {
  margin-bottom: 5px;
  font-weight: $font-weight-medium;
  font-size: rem(14px);
}
.list-item-image {
  float: right;
  max-width: 100px;
  margin-left: 10px;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
