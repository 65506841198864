/* -Breadcrumb links
---------------------------------------------------------------------------- */

// Prefer an even number for clean rounding
$breadcrumb-arrow-size: 8px;

.breadcrumb {
  @include wrap-wide;

  padding-top: 10px;
  padding-bottom: 10px;

  li {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: $breadcrumb-arrow-size * 4;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        width: $breadcrumb-arrow-size;
        height: $breadcrumb-arrow-size;
        margin-left: $breadcrumb-arrow-size;
        border-top: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg) translateY(-$breadcrumb-arrow-size * 0.5);
      }
    }
  }
  .logo {
    // Its visual center is slightly lower due to the J descender
    position: relative;
    top: 2px;
    width: round($logo-width * 0.7);
    height: round($logo-height * 0.7);
  }
  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
