/* -Search form and site search page
---------------------------------------------------------------------------- */
$color-support-result-green: desaturate(darken($color-green-pale, 25%), 5%);

.search-form {
  display: inline-flex;

  input {
    min-width: 200px;
    margin-right: 5px;
  }

  .search-page-content & {
    margin-top: 10px;
    width: 100%;

    @include media-min(rem(600px)) {
      width: auto;

      input {
        min-width: 400px;
      }
    }
  }
}

.search-header {
  text-align: center;
}

.search-page-empty-text {
  flex: 1;
}

.search-results {
  @include section-margin;

  .page-list-item-image & {
    max-width: 150px;
  }
}

.support-post-list-inner {
  background: $color-section-green-background;
  padding: 10px;
  border: 1px solid $color-support-result-green;

  p {
    margin: 15px 5px;
  }

  @include media-min(rem(600px)) {
    padding: 30px;
  }
}

.support-post-list--expanded {
  .support-post-item {
    display: flex;
  }
}

.support-post-list-header {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;

  .icon {
    width: 48px;
    height: 48px;
  }
  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
}

.support-post-item {
  display: none;
  flex-direction: row;
  position: relative;
  border-bottom: 1px solid $color-support-result-green;
  padding: 15px 5px;

  &:first-child {
    border-top: 1px solid $color-support-result-green;
  }

  &:nth-child(-n + 3) {
    display: flex;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}

.support-list-expand-toggle {
  color: inherit;
  margin-top: 1em;
}

.support-post-item-text {
  width: 100%;

  h4 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  span {
    font-size: 0.9rem;
  }
}

.support-post-item-link {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.support-post-list-expand {
  text-align: center;
}

@include media-min(rem(1400px)) {
  .search-page {
    .search-results {
      @include section-margin;
      @include grid-wrap;

      .support-post-list {
        @include grid-item(2*0.2);
      }
      .page-list {
        @include grid-item(3*0.2);
        margin-top: 0;
      }
    }
  }

  // Show all posts
  .support-post-item {
    display: flex;
  }
  .support-post-list-expand {
    display: none;
  }
}
