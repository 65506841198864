/* -The global footer area
-----------------------------------------------------------------------------*/
$site-footer-spacing: 40px;

.site-footer {
  background: $color-footer-background;
  color: $color-footer-foreground;

  a,
  .btn-plain {
    color: inherit;
  }
}

.site-footer-inner {
  padding-top: $site-footer-spacing;
  padding-bottom: $site-footer-spacing;
}
.site-footer-logo {
  margin-bottom: $site-footer-spacing;

  img {
    width: 110px;
  }
}

.footer-newsletter {
  margin-bottom: $site-footer-spacing;
}

@include media-min(rem(1200px)) {
  .site-footer-main {
    display: flex;
    width: 100%;
  }
  .site-footer-logo {
    @include grid-margin-right;

    margin-bottom: 0;
    flex-shrink: 0;

    img {
      width: 145px;
    }
  }
}

/* ------------------- Nav menu ------------------- */
$footer-nav-group-spacing: 40px;
.footer-nav {
  width: 100%;

  h4 {
    margin-bottom: 15px;
    font-size: 1em;
  }
  li:not(:first-child) {
    margin-top: 0.5em;
  }
  a,
  .btn-plain {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .icon-link {
    white-space: nowrap;
  }
}
.footer-nav-groups {
  margin-bottom: -$footer-nav-group-spacing;
}
.footer-nav-group {
  margin-bottom: $footer-nav-group-spacing;
}

@include media-min($breakpoint-footer-nav-cols-2) {
  .footer-nav-groups {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-nav-group {
    &:nth-child(odd) {
      width: 65%;
    }
    &:nth-child(even) {
      width: 35%;
    }
  }
}
@include media-min($breakpoint-footer-nav-cols-all) {
  .footer-nav-groups {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }
  .footer-nav-group {
    width: auto !important;
    flex: 1 1 auto;
    margin-bottom: 0;

    &:not(:last-child) {
      @include grid-padding-right;
    }
  }
}

// Previous, generated menu
/* .footer-nav {
  ul {
    list-style: none;
    margin: 0;
  }
  li {
    margin-top: 5px;
  }
  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  > ul {
    @include grid-wrap;

    display: flex;

    > li {
      @include grid-item-base;

      flex: 1 1 auto;

      > a {
        font-weight: $font-weight-bold;
      }
    }
  }
} */

/* ------------------- Contact and privacy info ------------------- */
.site-footer-main ~ .site-footer-privacy,
.site-footer-main ~ .footer-contact-info {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.site-footer-privacy {
  ul {
    list-style: none;
    margin: 0;
  }
  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 2em;
    }
  }
}
