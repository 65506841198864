/* -Intro image with content
---------------------------------------------------------------------------- */
$intro-content-padding: 100px;

.intro-slider {
  position: relative;
  max-height: 600px;
  overflow: hidden;

  @media screen and (orientation: landscape) {
    min-height: 60vh;
  }
  @media screen and (orientation: portrait) {
    min-height: 40vh;
  }

  // Ensure text is readable if image or video fails to load
  background-color: #666;

  .flickity-viewport {
    min-height: inherit;
  }
}

.intro-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;

  // Ensure only the first element is visible before Flickity is enabled to
  // prevent flacking.
  &:not(:first-child) {
    display: none;

    .flickity-enabled & {
      display: flex;
    }
  }
}

.intro-media {
  &,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.intro-content {
  position: relative;
  width: 100%;

  // Add additional top padding to account for absolutely positioned header
  // (a quick check shows it's about 130px tall with normal font sizes, it
  // doesn't have to be exact).
  padding-top: rem($intro-content-padding + 150px);
  padding-bottom: rem($intro-content-padding);
  color: #fff;
  text-align: center;
  z-index: 2;

  h1,
  h2 {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  p {
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  }
  @include media-max($breakpoint-menu-toggle) {
    padding-top: rem($intro-content-padding + 70px);
  }
}
