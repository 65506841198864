/* -Card slider/scroller
---------------------------------------------------------------------------- */

/* ------------------- Flickity base styles ------------------- */
// Adjusted version of the default Flickity CSS.
// Flickity v2.2.0 https://flickity.metafizzy.co

.flickity-enabled {
  position: relative;

  .hide-focus &:focus {
    outline: 0;
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

// Draggable
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

// flickity-button
.flickity-button {
  position: absolute;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: #333;
  color: #fff;
  transition: opacity 0.2s ease;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background: lighten(#333, 15%);
    }
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 5px $color-action;
    }
  }
  &:disabled {
    opacity: 0;
    cursor: auto;
    // prevent disabled button from capturing pointer up event. Issue 716.
    pointer-events: none;
  }
}
.flickity-button-icon {
  fill: currentColor;
}

// Previous/next buttons
/* .flickity-prev-next-button {
  top: 50%;
  width: 50px;
  height: 100px;
  transform: translateY(-50%);

  &.previous {
    left: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  &.next {
    right: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  // Right to left
  .flickity-rtl &.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl &.next {
    right: auto;
    left: 10px;
  }
  .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
} */

// Page dots
/* .flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  // .flickity-rtl & { direction: rtl; }
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;

    &.is-selected {
      opacity: 1;
    }
  }
} */

/* ------------------- Specifics ------------------- */
.card-slider-container {
  @include page-margin;

  padding-right: 0 !important;
}

.card-slider {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  padding: 20px 0;

  .page-card {
    // Using padding on last item for spacing at the end of the slider since
    // margin doesn't affect it. The padding can't affect the width.
    box-sizing: content-box;
    display: inline-block;
    display: inline-flex;
    vertical-align: top;
    flex-shrink: 0;
    width: 300px;
    min-height: 100%;
    margin-right: 30px;
    white-space: normal;

    &:last-child {
      padding-right: 30px;

      .overlay-link {
        width: auto;
        right: 30px;
      }
    }
  }
}

@include media-max(rem(450px)) {
  .card-slider .page-card {
    margin-right: 15px;
  }
}

@include media-max(rem(400px)) {
  .card-slider .page-card {
    width: 78vw;
  }
}
