/* -Callout block
---------------------------------------------------------------------------- */
.callout-block-wrap {
  @include section-margin;
}

.callout-block {
  padding: 20px 25px;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.callout-block-content {
  h2,
  h3,
  h4 {
    font-size: rem(20px);
    font-weight: $font-weight-semibold;
  }
}

img {
  .callout-block-image & {
    max-width: 120px;
  }
}

// Small screens only
@include media-max(rem(500px)) {
  .callout-block-image {
    max-width: 150px;
    margin: 0 auto 1em;
  }
  .callout-block-content {
    font-size: rem(14px);
  }
}

// Small to medium screens only
@include media-between(rem(501px), rem(699px)) {
  .callout-block {
    padding: 25px 30px;
  }
  .callout-block-image {
    float: left;
    max-width: 35%;
    margin: 0 1em 10px;
  }
}

// Larger screens only
@include media-min(rem(700px)) {
  .callout-block {
    display: flex;
    align-items: center;
    padding: 40px 50px;
  }
  .callout-block-image {
    flex: 1 1 auto;
    max-width: 170px;
    min-width: 120px;
    margin-right: 50px;
  }
}
