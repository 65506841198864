/* AUTOGENERATED, DO NOT EDIT MANUALLY */

/* montserrat-latin-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(../fonts/montserrat-latin-400-normal.woff2) format('woff2'), url(../fonts/montserrat-latin-400-normal.woff) format('woff');
}
/* montserrat-latin-500-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(../fonts/montserrat-latin-500-normal.woff2) format('woff2'), url(../fonts/montserrat-latin-500-normal.woff) format('woff');
}
/* montserrat-latin-600-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(../fonts/montserrat-latin-600-normal.woff2) format('woff2'), url(../fonts/montserrat-latin-600-normal.woff) format('woff');
}
/* montserrat-latin-700-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(../fonts/montserrat-latin-700-normal.woff2) format('woff2'), url(../fonts/montserrat-latin-700-normal.woff) format('woff');
}