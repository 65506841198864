/* -Grus & Guld search form and page
---------------------------------------------------------------------------- */
@use "sass:math";

.grus-guld-search-page-content {
  @include wrap-custom($gg-wrap-width-wide);

  .search-results-items {
    @include grid-wrap;
    @include grid-pull-bottom;
  }
  .grus-guld-card {
    @include grid-item(1);
    @include grid-margin-bottom;
  }
}

@include media-min(rem(800px)) {
  .grus-guld-search-page-content {
    .grus-guld-card {
      @include grid-item-columns(1*0.5);
    }
  }
}
@include media-min(rem(1100px)) {
  .grus-guld-search-page-content {
    .grus-guld-card {
      @include grid-item-columns(math.div(1, 3));
    }
  }
}
