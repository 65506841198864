/* -Main logo
---------------------------------------------------------------------------- */
.logo {
  display: inline-block;
  width: $logo-width;
  height: $logo-height;
  color: inherit !important;

  // The header logo size is the most important, hopefully it should fit well
  // enough in other places.
  // Shouldn't really use rem for the logo size, but it feels better to not
  // mix units when working with fixed height for the menu.
  @include media-max($breakpoint-menu-toggle) {
    width: rem($logo-width-small);
    height: rem($logo-height-small);
  }
}
