/* -Selection of news articles
---------------------------------------------------------------------------- */
.article-selection {
  @include section-padding;

  background: $color-gray-light;
}

.article-selection-content {
  text-align: center;
}
