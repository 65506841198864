/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
$secondary-nav-title-height: 30px;
$secondary-nav-item-height: rem(
  $secondary-nav-title-height + ($header-nav-spacing * 2)
);
$secondary-nav-item-line-height: 2;

.site-header {
  position: relative;
  width: 100%;
  z-index: $z-header;
}

.site-header-menu {
  a {
    color: inherit;

    .icon {
      margin: 0 3px 5px 0;
    }
  }
}

/* ------------------- Nav menus for large screens ------------------- */
// Only do apply when not in menu toggle mode since that usually overrides
// most of the large screen styles.
$sub-menu-open-delay: 75ms;
$sub-menu-close-delay: 200ms;
$sub-menu-transition-duration: 150ms;
@include media-min($breakpoint-menu-toggle + rem(1px)) {
  // ----- General header layout -----
  .site-header {
    display: table;
  }
  .site-header-main,
  .site-header-secondary {
    display: table-row;

    > div {
      display: table-cell;
    }
  }
  .site-header-main {
    > div {
      vertical-align: bottom;
    }

    li {
      // Nested li item within the dropdown menu
      &.hide-item {
        display: none !important;
      }
    }
  }
  .site-header-secondary {
    > div {
      padding-top: $header-nav-spacing;
      padding-bottom: $header-nav-spacing;
      vertical-align: top;
    }
  }
  .site-header-logo,
  .secondary-nav-title {
    width: 1%;
  }
  .secondary-nav-title {
    height: $secondary-nav-item-height;
    padding-left: 20px;
    padding-right: 40px;
    font-size: rem(18px);
    line-height: $secondary-nav-item-line-height;
    font-weight: $font-weight-semibold;
    text-align: right;
  }

  .site-header-logo {
    padding: $header-padding 40px $header-nav-spacing 40px;
  }
  .site-header-nav-row {
    display: flex;

    nav {
      padding-right: $header-padding;
    }
  }
  .login-nav,
  .help-nav {
    margin-left: auto;
    white-space: nowrap;
  }

  .header-search-small,
  .contact-us-small {
    display: none !important;
  }

  // ----- Shared between main nav and login nav -----
  .site-header-menu--primary {
    padding-top: $header-padding;

    ul[data-level='1'] {
      > li {
        padding-bottom: $header-nav-spacing;

        &:not(:last-child) {
          margin-right: 10px;
        }
        // First 4 items to the left, rest to the right. Account for hidden
        // search form used in the small screen menu.
        &:nth-child(6) {
          margin-right: auto;

          ~ li {
            margin-left: 10px;
          }
        }
        > a {
          padding: 5px 15px;
          border: 1px solid transparent;
          border-radius: 2px;
          font-size: rem(20px);
          font-weight: $font-weight-semibold;
        }
      }
      > li > a:not([role='button']):hover,
      > li > a:not([role='button']):focus,
      > .current-item > a,
      > .current-item-ancestor > a {
        border-color: currentColor;
      }
    }
  }
  .main-nav {
    flex-grow: 1;
    padding-right: 0 !important;

    > ul {
      display: flex;
    }
    // Hack for the start page being active an an ancestor when on a page
    // outside of the main nav tree.
    .is-outside-nav-tree & .current-item-ancestor > a {
      border-color: transparent;
    }
    // Can be included in the main nav for small screen menu, but has a
    // separate secondary position on larger screens.
    li[data-help-page] {
      display: none !important;
    }
  }

  // ----- Shared between sub nav and help nav -----
  .site-header-menu--secondary {
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    ul[data-level='1'] {
      > li:not(:last-child) {
        margin-right: 20px;
      }
      > li > a {
        position: relative;
        padding: 0 5px;
        line-height: $secondary-nav-item-line-height;

        &::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 5px;
          right: 5px;
          height: 1px;
          margin-bottom: 0.125em;
          background: transparent;
        }
      }
      > li > a:not([role='button']):hover::before,
      > li > a:not([role='button']):focus::before,
      > .current-item > a::before,
      > .current-item-ancestor > a::before {
        background: currentColor;
      }
    }
  }
  .secondary-nav {
    flex-grow: 1;
    // Match with horizontal link padding
    margin-left: -5px;

    .parent-item {
      font-weight: $font-weight-semibold;
    }
  }

  // ----- Navs with possible dropdowns, includes secondary ones -----
  .dropdown-nav {
    // Try to discourage clicks since they only open the sub menu
    a[role='button'] {
      cursor: default;
      transition: opacity 0.15s ease $sub-menu-close-delay;
    }
    .sub-menu {
      li {
        display: block;
      }
      a {
        padding: 5px 25px;
        white-space: nowrap;

        &:hover,
        &:focus {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      .current-item > a {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 10px;
          width: 6px;
          height: 6px;
          margin-left: -2px;
          border-top: 1px solid;
          border-right: 1px solid;
          transform: rotate(45deg) translateY(-50%);
        }
      }
      ul {
        a {
          padding-left: 45px;
        }
        .current-item > a::before {
          left: 30px;
        }
      }
      ul ul {
        a {
          padding-left: 65px;
        }
        .current-item > a::before {
          left: 50px;
        }
      }
    }
    > ul > li {
      position: relative;

      > .sub-menu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        min-width: 120%;
        // Match with ::before height
        margin-top: 8px;
        transform: translate(-50%, -5px);
        border-radius: 4px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
        background: #fff;
        color: $color-body-foreground;
        z-index: 1;
        transition: visibility 0s ease $sub-menu-close-delay +
            $sub-menu-transition-duration,
          opacity 0.15s ease $sub-menu-transition-duration,
          transform 0.15s ease $sub-menu-transition-duration;

        @media (prefers-reduced-motion: reduce) {
          transform: translate(-50%, 0) !important;
        }
        // ::before bridges the margin gap, otherwise the hover won't be active
        // when the cursor moves between the li and the menu. ::after is the
        // dropdown arrow.
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 100%;
        }
        &::before {
          width: 100%;
          // Match with .sub-menu margin-top
          height: 8px;
        }
        &::after {
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: #fff;
        }
        > li {
          &:first-child {
            padding-top: 10px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          &:last-child {
            padding-bottom: 10px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          > a {
            font-weight: $font-weight-semibold;
          }
        }
        > .current-item > a::before {
          border-width: 2px;
          width: 7px;
          height: 7px;
          margin-left: -4px;
        }
        a {
          font-size: rem(15px);
        }
      }
    }
    > ul > li:hover > .sub-menu,
    a[aria-expanded='true'] ~ .sub-menu {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0);
      transition-delay: $sub-menu-open-delay;
    }
    > ul > li:hover > a[role='button'],
    a[aria-expanded='true'] {
      opacity: 0.4;
      transition-delay: $sub-menu-open-delay;

      // Fading out the current location when tabbing is probably not very good
      .show-focus & {
        opacity: 1;
      }
    }
    a[aria-expanded='true'] ~ .sub-menu {
      // Remove open delay for keyboard and touch activation. Keep it above zero
      // for transition event.
      transition-delay: 0.1ms;
    }
    .title-item {
      padding-bottom: 10px;
      border-bottom: 1px solid $color-separator;

      a {
        font-size: rem(17px) !important;
        font-weight: $font-weight-semibold;
      }
      + li {
        padding-top: 10px;
      }
    }
  }
}

/* ------------------- Small screen toggled menu ------------------- */
$menu-toggle-height: 40px;
.menu-toggle {
  display: none !important;
  position: relative;
  height: rem($menu-toggle-height);
  line-height: rem($menu-toggle-height - 2px);
  padding-right: 35px;
  border: 1px solid transparent;
  box-shadow: none;
  background: transparent !important;
  color: inherit;
  font-weight: $font-weight-regular;
  text-transform: none;
  transition: color 0.15s ease;

  &:hover,
  &:focus {
    border-color: currentColor;
  }
}
.menu-toggle-icon {
  top: 50%;
  right: 15px;
  margin-top: -1px;

  &,
  &::before,
  &::after {
    position: absolute;
    width: 13px;
    height: 2px;
    background-color: currentColor;
    transition: background-color 0.15s ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    transition: transform 0.15s ease;
  }
  &::before {
    top: 0;
    transform: translateY(-5px);
  }
  &::after {
    bottom: 0;
    transform: translateY(5px);
  }
  .nav-open & {
    background-color: rgba(255, 255, 255, 0);

    &::before {
      transform: translateY(0) rotate(-225deg);
    }
    &::after {
      transform: translateY(0) rotate(225deg);
    }
  }
}

// Hide behind a menu toggle. Sets a fixed height to header elements since the
// toggled menu relies on it. Logo size is set in _logo.scss.
$header-small-padding: 15px;
$header-small-height: max($logo-height-small, $menu-toggle-height) +
  ($header-small-padding * 2);
$small-menu-horizontal-padding: 15px;
$small-menu-scroll-buffer: 110px;
@include media-max($breakpoint-menu-toggle) {
  // Hide sub nav, help nav and search
  .site-header-secondary {
    display: none !important;
  }

  // ----- Primary nav menu behind a toggle -----
  .nav-open,
  .nav-open body {
    overflow: hidden;
  }
  .site-header-main {
    display: flex;
    align-items: center;
    height: $header-small-height;

    > div {
      padding-top: $header-small-padding;
      padding-bottom: $header-small-padding;
    }
  }
  .site-header-logo {
    padding-left: $page-margin-small;
    padding-right: 5px;
  }
  .site-header-menu--primary {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 5px;
    padding-right: $small-menu-horizontal-padding;
  }
  .menu-toggle {
    display: inline-block !important;
    display: inline-flex !important;
    margin-left: 20px;
    z-index: $z-header + 10;

    .nav-open & {
      background-color: #fff !important;
      color: $color-body-foreground;
      border-color: $color-body-foreground;
    }
  }
  .login-nav {
    a {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .main-nav {
    visibility: hidden;
    width: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.2s ease, visibility 0s ease 0.2s,
      width 0s ease 0.2s;

    .nav-open & {
      visibility: visible;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition-delay: 0s;
    }
  }
  // main-nav::after is a small bottom fade to indicate scrollability
  .main-nav > ul,
  .site-header-menu--primary::after,
  .main-nav::after {
    width: 100%;
    max-width: 350px;
    background: #fff;
    transform: translateX(100%);
    transition: transform 0.2s ease;

    .nav-open & {
      transform: translateX(0);
    }
  }
  .main-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: right;
    z-index: $z-header + 1;

    &::after {
      content: '';
      display: block;
      position: fixed;
      bottom: 0;
      right: 0;
      height: $small-menu-scroll-buffer * 0.5;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
      pointer-events: none;
      z-index: $z-header + 5;
    }
    > ul {
      display: inline-block;
      min-height: 100%;
      text-align: left;
      color: $color-body-foreground;

      > li:first-child {
        padding-top: $header-small-height;
      }
      > li:last-child {
        padding-bottom: $small-menu-scroll-buffer;
      }
    }
    li {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    li,
    a {
      display: block;
      width: 100%;
    }
    a {
      padding: 7px $small-menu-horizontal-padding;

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.08);
      }
    }
    .title-item {
      display: none;
    }
    .current-item > a {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 7px;
        height: 2px;
        margin-top: -1px;
        background: currentColor;
      }
    }
    ul ul {
      a {
        padding-left: 35px !important;
      }
      .current-item > a::before {
        left: 20px;
      }
    }
    ul ul ul {
      a {
        padding-left: 55px !important;
        font-size: rem(14px);
        // Occupy the same height as regular sized items
        line-height: strip-unit(em($base-font-size * $base-line-height, 14px));
      }
      .current-item > a::before {
        left: 40px;
      }
    }
    > ul > li {
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      > a {
        font-weight: $font-weight-semibold;
      }
    }
  }
  .header-search-small {
    padding: 0 $small-menu-horizontal-padding 15px;
    border-top: 0 !important;

    .search-form {
      width: 100%;
    }
    input {
      min-width: 0;
    }
  }
}
@include media-max(rem(400px)) {
  .menu-toggle {
    margin-left: 10px;
  }
  .main-nav > ul,
  .site-header-menu--primary::after,
  .main-nav::after {
    max-width: none;
  }
}

// Ugly hack for Chrome triggering transitions on page load, disable them until
// the menu is ready.
// https://stackoverflow.com/questions/14389566/stop-css3-transition-from-firing-on-page-load
.no-menu-ready {
  .main-nav,
  .main-nav::after,
  .main-nav > ul,
  .site-header-menu--primary::before,
  .site-header-menu--primary::after,
  .dropdown-nav > ul > li > .sub-menu {
    transition: none !important;
  }
}

/* ------------------- Search ------------------- */
.header-search-item {
  position: relative;

  > a {
    .text {
      border-bottom: 1px solid transparent;
    }
    &:hover,
    &:focus {
      text-decoration: none;

      // Remove styles for buttonized dropdown links. Any selectors they use
      // will hit this as well.
      opacity: 1 !important;
      cursor: pointer !important;

      .text {
        border-bottom-color: currentColor;
      }
    }
    .icon {
      margin-right: 0.25em;
    }
  }
}
.header-search {
  display: none;

  position: absolute;
  top: 100%;
  right: 0;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;

  .search-open & {
    display: block;
  }
}

/* ------------------- With intro ------------------- */
.has-intro {
  .site-header {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 140%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.4) 35%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: -1;
    }
  }
  .site-header-menu--secondary {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
}
