/* -Internal ad
---------------------------------------------------------------------------- */
$internal-ad-spacing: 30px;
$internal-ad-spacing-small: $internal-ad-spacing*0.5;

.internal-ad-wrap {
  @include section-margin(0.5);
}
.internal-ad {
  padding: $internal-ad-spacing-small;
  display: flex;
  background-color: $color-green-pale;
}
.internal-ad-image {
  margin-right: $internal-ad-spacing-small;
  max-width: 130px;
}
.internal-ad-content {
  flex-basis: 65%;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: rem(14px);
}
.internal-ad-link {
  margin-top: 15px;

  a {
    @include button-brand;
    @include btn-small-lowercase;
  }
}

@include media-min(rem(500px)) {
  .internal-ad {
    padding: $internal-ad-spacing;
  }
  .internal-ad-image {
    margin-right: $internal-ad-spacing;
  }
}
