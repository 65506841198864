/* -Blurb component - block of image, heading, short text and a link
-----------------------------------------------------------------------------*/
@use "sass:math";

.blurb {
  max-width: 400px;
  text-align: left;
}

.blurb--svg {
  .blurb-inner {
    display: flex;
  }
  .blurb-image {
    flex: 1 1 15%;
    margin-bottom: 15px;
  }
  .blurb-content {
    flex: 1 1 85%;
  }
}

.blurb--img {
  .blurb-image {
    margin-bottom: 10px;
  }
}

.blurb-content {
  @include tight-text;

  font-size: rem(16px);
  margin-left: 10px;

  @include media-max(rem(700px)) {
    font-size: rem(14px);
  }
  .link-block {
    font-weight: $font-weight-medium;
  }
}

.blurb-title {
  margin-bottom: 5px;
  font-size: rem(20px);
}

.blurb-text {
  margin-bottom: 5px;
}

/*--------------- List of blurbs (blurbs block, news...) ---------------*/
.blurb-list-wrap {
  @include section-margin;

  text-align: center;

  // Remove duplicate page margins if blurbs is inside content selection block
  .block-content-selection & {
    @include page-margin-reset;
  }
}

.blurb-list {
  @include grid-wrap;

  margin-bottom: -50px;

  .blurb {
    @include grid-item(1);

    margin-bottom: 50px;
  }
}

@include media-min(rem(650px)) {
  .blurb-list--cols-2,
  .blurb-list--cols-3,
  .blurb-list--cols-4,
  .blurb-list--count-4 {
    .blurb {
      @include grid-item-columns(1 * 0.5);
    }
  }

  .blurb--svg {
    .blurb-inner {
      display: inline-block;
    }
    .blurb-content {
      margin-left: 0;
    }
  }
}

@include media-min(rem(1000px)) {
  .blurb-list-wrap--cols-3,
  .blurb-list-wrap--cols-4 {
    @include wrap-width-wide;
  }
  .blurb-list--cols-3 .blurb {
    @include grid-item-columns(math.div(1, 3));
  }
  .blurb-list--cols-4 .blurb {
    @include grid-item-columns(1 * 0.25);
  }
}

/* ------------------- Green blocks variant ------------------- */
// Flexbox for equal height blocks
.blurb-list--green-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: $color-body-foreground;

  ul {
    @include checklist;
  }
  .blurb {
    display: flex;
    flex-direction: column;
  }
  .blurb-inner {
    flex-grow: 1;
    padding: 20px;
    border-radius: 15px;
    background: $color-section-green-background;

    @include media-min(rem(1200px)) {
      padding: 30px;
      border-radius: 20px;
    }
  }
  .blurb--svg {
    .blurb-inner {
      display: inline-block;
    }
    .blurb-content {
      margin-left: 0;
    }
  }
  .blurb-image,
  .blurb-title {
    text-align: center;
  }
  .blurb-title {
    margin-bottom: 1em;
  }
}
