/* -Grus & Guld detail page
---------------------------------------------------------------------------- */
$gg-detail-negative-margin-large: rem(110px);
$gg-detail-breakpoint-negative-margin-large: rem(1300px);

/* -------- Detail page -------- */
.grus-guld-article-header {
  @include section-margin-bottom;

  text-align: center;

  .lead-text {
    margin-bottom: 0;
  }
}
.grus-guld-article-header-content {
  @include wrap-custom(rem(1060px));

  h1 {
    i {
      font-style: normal;
    }
  }
}
.grus-guld-article-header-author {
  margin-top: 20px;
  text-transform: uppercase;
  color: $color-green;
}
.grus-guld-article-image {
  @include edge-wrap-custom($gg-wrap-width-wide);
  @include section-margin-top;
}

.grus-guld-article-inner {
  @include wrap-custom(rem(1060px));
}
.grus-guld-detail-sidebar {
  align-self: flex-start;
}
.grus-guld-article-content {
  @include section-margin-bottom(0.5);

  flex-shrink: 0;
  max-width: $gg-wrap-width-thin;
  margin-left: auto;
  margin-right: auto;

  h2,
  h3,
  h4,
  h5,
  h6 {
    @include h3;
  }

  hr {
    border-color: $color-separator;
  }

  blockquote {
    position: relative;

    @include h;
    @include h2;

    font-style: normal;
    color: $color-green;

    .rich-text {
      padding-top: 15px;

      ::before {
        display: inline-block;
        margin-right: 10px;
        width: 40px;
        height: 30px;
        background-image: svg-url(
          '<svg xmlns="http://www.w3.org/2000/svg" width="74" height="56" viewBox="0 0 74 56"><path fill="#0e6946" fill-rule="evenodd" d="M32.014 4.406c3.198 2.937 4.797 7.016 4.797 12.237 0 3.916-1.501 8.355-4.503 13.315L16.643 56H0l10.182-26.042c-4.438-3.133-6.658-7.571-6.658-13.315 0-4.83 1.567-8.81 4.7-11.944C11.357 1.566 15.338 0 20.168 0c4.7 0 8.648 1.469 11.846 4.406zm36.811 0c3.198 2.937 4.797 7.016 4.797 12.237 0 3.916-1.5 8.355-4.503 13.315L53.455 56H36.81l10.182-26.042c-4.438-3.133-6.657-7.571-6.657-13.315 0-4.83 1.566-8.81 4.699-11.944C48.168 1.566 52.149 0 56.979 0c4.7 0 8.648 1.469 11.846 4.406z"/></svg>'
        );
        background-repeat: no-repeat;
        background-size: 40px 30px;
        content: '';
      }

      ::after {
        content: '';
        position: absolute;
        height: 5px;
        border-radius: 3px;
        width: 180px;
        left: 0;
        top: 0;
        background-color: $color-green;
      }
    }

    @include media-min($gg-detail-breakpoint-negative-margin-large) {
      margin-left: -$gg-detail-negative-margin-large;

      .rich-text {
        padding-top: 25px;
        margin-left: $gg-detail-negative-margin-large;

        ::before {
          position: absolute;
          width: -$gg-detail-negative-margin-large;
          left: 0;
          width: 60px;
          height: 50px;
          background-size: 60px 50px;
        }
      }
    }
  }
}

.grus-guld-images-block {
  @include section-margin(0.5);

  [class*='images-block--cols-'] {
    @include grid-pull-bottom;
  }
  .images-block-item-caption p {
    margin-bottom: 0;
  }
}

.grus-guld-detail-share {
  margin-top: rem(20px);

  .share-title {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-separator;
    font-weight: $font-weight-regular;
    font-size: rem(12px);
  }
}

@include media-min($gg-detail-breakpoint-negative-margin-large) {
  .grus-guld-images-block {
    margin-left: -$gg-detail-negative-margin-large;

    .images-block:not(.images-block--side-text) {
      .images-block-item-caption {
        margin-left: $gg-detail-negative-margin-large;
      }
    }
  }
}

@include media-min($gg-breakpoint-sidebar-in-col) {
  .grus-guld-article-inner {
    display: flex;
  }
  .grus-guld-article-content {
    margin-right: rem(40px);
    margin-bottom: 0;
  }
}
.grus-guld-article-footer {
  max-width: $gg-wrap-width-thin;
  margin-left: auto;
  margin-top: rem(40px);

  ul {
    margin: 0;
    list-style: none;
  }

  $gg-categories-spacing: 20px;
  .footer-meta-tags ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$gg-categories-spacing;

    li {
      margin-right: $gg-categories-spacing;
      margin-bottom: $gg-categories-spacing;
    }
  }

  .footer-meta-author {
    margin-top: rem(20px);

    > p {
      margin-bottom: 10px;
      font-size: rem(12px);
    }

    ul {
      padding: 10px 0;
      border-top: 1px solid $color-separator;
    }
    li + li {
      margin-top: 15px;
    }

    img {
      width: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
  .footer-meta-date {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .publish-date,
  .last-published-date {
    font-size: rem(12px);
  }
  .last-published-date {
    margin-right: 10px;
  }
  .publish-date {
    color: $color-lightest-accessible-gray-on-white;
  }
}
@include media-min(rem(600px)) {
  .grus-guld-article-content {
    font-size: rem(18px);
  }
}

@include media-min(rem(1000px)) {
  .grus-guld-article {
    .lead-text {
      font-size: rem(28px);
    }
  }
}

@include media-min(rem(1400px)) {
  .grus-guld-article {
    h1 {
      font-size: rem(52px);

      i {
        font-size: $h1;
      }
    }
  }
}

$related-articles-gutter: rem(40px);
$related-articles-gutter-tight: $related-articles-gutter*0.5;
// Related articles
.related-articles {
  @include wrap-custom($gg-wrap-width-wide);
  @include section-margin-top;
}
.related-articles-title {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-separator;
  font-weight: $font-weight-regular;
  font-size: rem(12px);
}
.related-articles-items {
  @include grid-wrap($related-articles-gutter, $related-articles-gutter-tight);
  @include grid-pull-bottom(
    $related-articles-gutter,
    $related-articles-gutter-tight
  );

  .grus-guld-card {
    @include grid-item(
      1,
      $related-articles-gutter,
      $related-articles-gutter-tight
    );
    @include grid-margin-bottom(
      $related-articles-gutter,
      $related-articles-gutter-tight
    );
  }
}

@include media-min(rem(600px)) {
  .related-articles-items {
    .grus-guld-card {
      @include grid-item-columns(1*0.5);
    }
  }
}
@include media-min(rem(1200px)) {
  .related-articles-items {
    .grus-guld-card {
      @include grid-item-columns(1*0.25);
    }
  }
}
