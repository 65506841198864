$wrap-width: rem(850px);
$wrap-width-thin: rem(700px);
$wrap-width-wide: rem(1140px);
$wrap-width-mega: rem(1550px);

// Wrap widths used for Grus & Guld
$gg-wrap-width-wide: rem(1280px);
$gg-wrap-width-thin: rem(620px);

$page-margin: rem(50px);
$page-margin-small: $page-margin * 0.5;
$section-spacing: 60px;

$logo-width: 78px;
$logo-height: 40px;
$logo-width-small: round($logo-width * 0.8);
$logo-height-small: round($logo-height * 0.8);

$header-padding: 20px;
$header-nav-spacing: 10px;
$header-logo-spacing: 40px;

$arrow-link-padding: 5px;

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 15px;
$form-control-radius: 5px;
$form-field-padding: em(10px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 16px;
$button-height: em($form-control-base-height, $button-font-size);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

// Reduce text sizes
$breakpoint-type-medium: rem(1200px);
$breakpoint-type-small: rem(450px);

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(550px);

$breakpoint-menu-toggle: rem(1150px);

$breakpoint-footer-nav-cols-2: rem(550px);
$breakpoint-footer-nav-cols-all: rem(850px);

// Grus & Guld breakpoints
$gg-breakpoint-sidebar-in-col: rem(1200px);
