/* -Global notice
---------------------------------------------------------------------------- */
.notice {
  display: flex;
  padding: 10px $page-margin;

  > .icon {
    flex: 0 0 auto;
    margin-top: 0.25em;
    margin-right: 10px;
  }

  @include media-max($breakpoint-menu-toggle) {
    padding-left: $page-margin-small;
    padding-right: $page-margin-small;
  }
  @include media-max(rem(600px)) {
    font-size: rem(14px);
  }
}

.notice--info {
  border-bottom: 1px solid rgba($color-info-foreground, 0.2);
  background: $color-info-background;
  color: $color-info-foreground;
}
.notice--warning {
  border-bottom: 1px solid rgba($color-warning-foreground, 0.2);
  background: $color-warning-background;
  color: $color-warning-foreground;
}
