/* -Content selection block
---------------------------------------------------------------------------- */
.block-content-selection {
  max-width: 800px;

  &.block-content-selection--horizontal {
    @include wrap-wide;
  }

  &.block-content-selection--center {
    text-align: center;
  }
}

.content-selection-tabs {
  button {
    display: block;
    width: 100%;
    height: auto;
    border: 1px solid transparent;
    box-shadow: none;
    background: transparent;
    color: $color-action;
    font-weight: $font-weight-medium;
    line-height: 1.5;
    text-align: left;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: #fff;
    }
    &:hover {
      border-color: $color-action;
    }
    &[aria-selected='true'] {
      border-color: transparent;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      background: #fff;
      color: inherit;
    }
  }
}

.content-selection-panel {
  img {
    max-width: 250px;
  }
  h3:not(:first-child) {
    margin-top: 1rem;
  }

  // Decrease width for some content blocks
  .block-content-selection--horizontal & {
    > *:not(.blurb-list-wrap) {
      @include edge-wrap-thin;
    }
  }
}

// Small screen version only
@include media-max(rem(649px)) {
  .content-selection-tabs {
    margin-bottom: 10px;

    button {
      margin-bottom: 0.5em;
      // Match with panel padding
      padding: 0.5em 10px;
      font-size: rem(14px);
    }
  }
  .content-selection-panel {
    // Match with button padding
    padding-left: 10px;
    padding-right: 10px;
  }
}

// Larger screen version only
@include media-min(rem(650px)) {
  .block-content-selection--vertical {
    display: flex;

    .content-selection-tabs {
      @include grid-margin-right;

      flex: 0 0 auto;
      width: em(250px);

      button ~ button {
        margin-top: 20px;
      }
    }
  }
  .block-content-selection--horizontal {
    .content-selection-tabs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;

      button {
        width: em(250px);
        margin-right: 20px;
        padding: 0.75em 1.5em;
        text-align: center;
      }
    }
  }
  .content-selection-tabs {
    button {
      padding: 0.75em 1.5em;
    }
  }
}

// Without JS each panel is just displayed in sequence
.no-js {
  .block-content-selection {
    @include wrap-width-thin;

    display: block;
  }
  .content-selection-panel {
    margin-bottom: $text-spacing;
  }
}

// With JS, some attributes including `hidden` are set by JS and the styling
// for the panels looks broken until that happens. Hide all panels except the
// first until tabindex is set, which is also done with JS.
.js {
  .content-selection-panel + .content-selection-panel:not([tabindex]) {
    display: none;
  }
}
