/* Social media icon sharing links
---------------------------------------------------------------------------- */
.share-links {
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin: 0 20px 15px 0;

    &:last-child {
      margin-right: 0;
    }
  }
  .icon {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    vertical-align: top;

    &.icon--linkedin {
      color: #0077b5;
    }
    &.icon--facebook {
      color: #3c5a99;
    }
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    .text {
      color: $color-body-foreground;
      font-size: rem(12px);
    }

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
