/* -Contact me block
---------------------------------------------------------------------------- */
.contact-me-block {
  padding: 1em 1.25em 0.5em;
  background: $color-section-green-background;

  .contact-block-info {
    @include grid-item(1);
  }
  h3 {
    margin-top: 1.5rem;
  }
  .section-block--green & {
    padding: 0;
  }
}
