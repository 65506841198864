/* -Blog like list of pages
---------------------------------------------------------------------------- */
.page-list {
  @include section-margin;

  display: block;
  h3 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 1.1rem;
  }
}

.page-list-item {
  margin-bottom: 30px;

  overflow: hidden;

  .publish-date {
    margin-bottom: 10px;
    font-size: rem(13px);
  }
}

.page-list-item-image {
  float: left;
  max-width: 200px;
  margin-right: 20px;
}

@include media-min(rem(600px)) {
  .page-list-item-content {
    overflow: hidden;
  }
}
