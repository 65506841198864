/* -Q&A blocks
---------------------------------------------------------------------------- */

.blocks--questions-and-answers {
  @include section-margin;
}

.blocks--questions-and-answers__content {
  @include section-margin-bottom(0.5);
}

.qa-block {
  padding: 15px 5px 15px 10px;
  border-top: 1px solid $color-separator;

  &:last-child {
    border-bottom: 1px solid $color-separator;
  }
}
.qa-block__toggle {
  margin-left: auto;
  flex-shrink: 0;
  padding: 5px;

  .icon {
    width: 32px;
    height: 32px;
    margin: 0 !important;
    padding: 0;
    border-radius: 50%;
    background-color: $color-green-light;
    transform: rotate(90deg);
    transition: transform 0.15s ease;

    svg {
      width: 10px;
    }
  }
  &:hover,
  &:focus {
    .icon {
      background-color: darken($color-green-light, 5%);
    }
  }
  .qa-block--open & {
    .icon {
      transform: rotate(270deg);
    }
  }
}
.qa-block__question {
  display: flex;
  align-items: center;
  font-size: rem(18px);
  font-weight: $font-weight-bold;

  p {
    margin-bottom: 0;
  }
}
.qa-block__answer {
  display: none;
  margin-top: 5px;

  .qa-block--open & {
    display: block;
  }
}

@include media-min(rem(450px)) {
  .qa-block {
    padding: 15px 10px 15px 15px;
  }
}
