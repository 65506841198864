/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-arrow-width: 16px;
$icon-arrow-height: 16px;
// Size matches base icon size, no rule generated

$icon-chevron-width: 7.1px;
$icon-chevron-height: 11.3px;
.icon--chevron {
  width: $icon-chevron-width;
  height: $icon-chevron-height;
}

$icon-curly-bracket-small-width: 11px;
$icon-curly-bracket-small-height: 70px;
.icon--curly-bracket-small {
  width: $icon-curly-bracket-small-width;
  height: $icon-curly-bracket-small-height;
}

$icon-curly-bracket-width: 11px;
$icon-curly-bracket-height: 100px;
.icon--curly-bracket {
  width: $icon-curly-bracket-width;
  height: $icon-curly-bracket-height;
}

$icon-facebook-width: 16px;
$icon-facebook-height: 16px;
// Size matches base icon size, no rule generated

$icon-forum-width: 16px;
$icon-forum-height: 16px;
// Size matches base icon size, no rule generated

$icon-info-width: 16px;
$icon-info-height: 16px;
// Size matches base icon size, no rule generated

$icon-instagram-width: 16px;
$icon-instagram-height: 16px;
// Size matches base icon size, no rule generated

$icon-linkedin-width: 16px;
$icon-linkedin-height: 16px;
// Size matches base icon size, no rule generated

$icon-mail-width: 16px;
$icon-mail-height: 16px;
// Size matches base icon size, no rule generated

$icon-newspaper-width: 18px;
$icon-newspaper-height: 15px;
.icon--newspaper {
  width: $icon-newspaper-width;
  height: $icon-newspaper-height;
}

$icon-press-width: 16px;
$icon-press-height: 16px;
// Size matches base icon size, no rule generated

$icon-search-width: 16px;
$icon-search-height: 16px;
// Size matches base icon size, no rule generated

$icon-warning-width: 16px;
$icon-warning-height: 16px;
// Size matches base icon size, no rule generated

$icon-twitter-width: 24px;
$icon-twitter-height: 24px;
.icon--twitter {
  width: $icon-twitter-width;
  height: $icon-twitter-height;
}