/* -'Images' block
-----------------------------------------------------------------------------*/
@use "sass:math";

.images-block-item {
  > a {
    display: block;
  }
}

.images-block-item-caption {
  .images-block--cols-1 &,
  .images-block--cols-2 &,
  .images-block--cols-3 &,
  .images-block--cols-4 &,
  .images-block--gallery & {
    margin-top: 5px;
    font-size: rem(14px);

    p:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}

.images-block-item-image {
  a {
    transition: opacity 0.15s ease;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}
/*-------------------- Small centered layoyt --------------------*/
.images-block--small_center {
  .images-block-item {
    @include grid-margin-bottom;

    max-width: rem(215px);
    margin-right: auto;
    margin-left: auto;
  }
}

/*-------------------- Columns layout --------------------*/
.images-block--cols-1,
.images-block--cols-2,
.images-block--cols-3,
.images-block--cols-4 {
  @include grid-wrap;

  .images-block-item {
    @include grid-item(1);
    @include grid-margin-bottom;
  }
}
.images-block--cols-2,
.images-block--cols-4 {
  .images-block-item {
    @include grid-item-columns(1*0.5);
  }
}
.images-block--cols-3 {
  .images-block-item {
    @include grid-item-columns(math.div(1, 3));
  }
}
@include media-max(500px) {
  .images-block--cols-4 {
    @include grid-item-columns(1*0.25);
  }
}

/*-------------------- Gallery layout --------------------*/
$images-block-gallery-gutter: 30px;
.images-block--gallery {
  @include grid-wrap(
    $images-block-gallery-gutter,
    $images-block-gallery-gutter * 0.5
  );

  .images-block-item {
    @include grid-item(
      1 * 0.25,
      $images-block-gallery-gutter,
      $images-block-gallery-gutter * 0.5
    );
    @include grid-margin-bottom(
      $images-block-gallery-gutter,
      $images-block-gallery-gutter * 0.5
    );
  }
  .images-block-item:first-child,
  .images-block-item:first-child + .images-block-item {
    @include grid-item-columns(1 * 0.5);
  }
}

/*-------------------- Text-to-the-side layout --------------------*/
.images-block--side-text {
  @include grid-margin-top;

  .images-block-item {
    @include grid-wrap;
    @include grid-margin-bottom;

    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .images-block-item-image,
  .images-block-item-caption {
    @include grid-item-gutter;
  }
  .images-block-item-image {
    flex-shrink: 0;
    max-width: 50%;
  }

  @include media-max(rem(550px)) {
    margin-bottom: -50px;

    .images-block-item {
      @include grid-wrap-reset;

      display: block;
      margin-bottom: 50px;
    }
    .images-block-item-image,
    .images-block-item-caption {
      @include grid-item-reset;
    }
    .images-block-item-image {
      max-width: none;
    }
    .images-block-item-caption {
      margin-top: 10px;
    }
  }
}
