.mail-list-block {
  @include wrap-thin;
  @include section-margin;
}

.email-list-subscription-block {
  label {
    @include visuallyhidden;
  }
  .form-field {
    margin-top: 0;

    input {
      max-width: rem(400px);
    }
  }
  .form-success {
    margin-bottom: 10px;
  }
  .form-errors-wrap {
    width: 100%;
  }
}
