/* -IAmJAK block, single and multiple
---------------------------------------------------------------------------- */
@use "sass:math";

$i-am-jak-size: 230px;

/* ------------------- Single item ------------------- */
.i-am-jak-article {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: $i-am-jak-size;

  .overlay-link:hover,
  .overlay-link:focus {
    ~ .arrow-link .text {
      text-decoration: underline;
    }
  }
}

.i-am-jak-image {
  position: relative;
}
.i-am-jak-image-placeholder {
  // Match with image size set in the template
  padding-bottom: percentage(math.div(450, 350));
  background: $color-gray-dark;
}
.i-am-jak-image-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: $font-weight-bold;
  text-align: center;
  text-transform: uppercase;
}

/* ------------------- List of items ------------------- */
.block-i-am-jak--cols-4,
.block-i-am-jak--cols-5 {
  @include wrap-width-wide;
}

.i-am-jak-list {
  @include grid-wrap;

  display: flex;
  justify-content: center;

  .i-am-jak-article {
    @include grid-item-base;

    max-width: $i-am-jak-size + $grid-gutter;
  }
}
