/* -Category label used for news and search
---------------------------------------------------------------------------- */
.category {
  margin-bottom: 5px;
  font-size: rem(13px);
  color: $color-green;

  .icon {
    margin: 0 2px 2px 0;
    width: 16px;
  }
}
